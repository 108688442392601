import TruckIcon from '@/components/icons/TruckIcon.vue'
import SemiTrailerIcon from '@/components/icons/SemiTrailerIcon.vue'
import TransporterIcon from '@/components/icons/TransporterIcon.vue'

interface Category {
  position: number
  slug: string
  icon:
    | typeof TruckIcon
    | typeof SemiTrailerIcon
    | typeof TransporterIcon
    | null
  img: string | null
  path: string
  title: string
}

export const useSortedCategories = (): Category[] => {
  const config = useRuntimeConfig().public

  return [
    {
      position: config.CATEGORY_ORDER.TRAILER,
      slug: 'semi_trailer',
      icon: SemiTrailerIcon,
      img: 'semi_trailer_image.png',
      path: '/auflieger',
      title: 'Auflieger',
    },
    {
      position: config.CATEGORY_ORDER.TRUCK,
      slug: 'truck',
      icon: TruckIcon,
      img: 'truck_image.png',
      path: '/sattelzugmaschinen',
      title: 'Sattelzugmaschinen',
    },
    {
      position: config.CATEGORY_ORDER.LKW,
      slug: 'lkw',
      icon: TransporterIcon,
      img: 'lkw_image.png',
      path: '/lkw',
      title: 'LKW',
    },
    {
      position: config.CATEGORY_ORDER.TRANSPORTER,
      slug: 'transporter',
      icon: null,
      img: null,
      path: '/transporter',
      title: 'Transporter',
    },
    {
      position: config.CATEGORY_ORDER.BODY,
      slug: 'body',
      icon: null,
      img: null,
      path: '/wechselbruecke',
      title: 'Wechselbrücke',
    },
    {
      position: config.CATEGORY_ORDER.TRAILER,
      slug: 'trailer',
      icon: null,
      img: null,
      path: '/anhaenger',
      title: 'Anhänger',
    },
    {
      position: config.CATEGORY_ORDER.CONTAINER,
      slug: 'container',
      icon: null,
      img: null,
      path: '/container',
      title: 'Container',
    },
  ].sort((x, y) => x.position - y.position)
}

export const useCategoryNavigationObject = (slug: string) => {
  const category = useSortedCategories().find(
    (category) => category.slug === slug
  )

  if (!category) {
    return { title: '', path: '' }
  }

  return { title: category.title, path: category.path }
}
